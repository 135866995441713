import { IThemeTypes } from "../models/theme";

export const SWITCH_THEME = "SWITCH_THEME";

export interface ThemeActionTypes { 
    type: typeof SWITCH_THEME, 
    payload: IThemeTypes
}

export const SwitchTheme = (themeParams: IThemeTypes): ThemeActionTypes => {
    return (
        { 
            type: SWITCH_THEME, 
            payload: themeParams
        }
    )
}