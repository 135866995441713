import { useMemo } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { ClassicTheme, DarkTheme } from "./assets/themes";
import { NavBar } from "./components/navbar/navbar";
import { IThemeTypes, IThemeParams } from "./models/theme";
import { GlobalRouter } from "./modules/globalRouter";
import { useAppSelector } from "./store";
import "./i18n";

declare module "styled-components" {
  export interface DefaultTheme extends IThemeParams {}
}

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    //transition: background-color .2s, border .2s;
  }
  body {
    background-color: ${({ theme }) => theme.backgroundBody};
    color: ${({ theme }) => theme.color};
  }
`;

const App = () => {
  const theme = useAppSelector((state) => state.theme);
  const currentTheme = useMemo(() => {
    switch (theme) {
      case IThemeTypes.CLASSIC:
        return ClassicTheme;
      case IThemeTypes.DARK:
        return DarkTheme;
    }
  }, [theme]);
  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      <GlobalRouter>
        <NavBar />
      </GlobalRouter>
    </ThemeProvider>
  );
};

export default App;
