import { FormStyled } from "../components/styled-components/form";
import { GenericsStyled } from "../components/styled-components/generics";
import React, { useState } from "react";
import styled from "styled-components";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { IUserChangePassword } from "../models/user";
import { useForm } from "react-hook-form";
import { changePassword } from "../services/user";
import { AxiosError } from "axios";
import { useAppDispatch, useAppSelector } from "../store";
import { Loading } from "../actions/loading";

const AccountContainer = styled(FormStyled.Container)`
  margin-top: 30px;
`;

const AccountInput = styled(FormStyled.Input)`
  margin-bottom: 30px;
`;

const ChangePasswordContainer = styled(FormStyled.FormContainer)`
  height: inherit !important;
`;

export const ChangePassword = () => {
  const [responseState, setResponseState] = useState<
    "success" | "alert" | undefined
  >();

  const dispatch = useAppDispatch();
  const [responseMessage, setResponseMessage] = useState<string | undefined>();
  const token = useAppSelector((state) => state.user.token);
  const loading = useAppSelector((state) => state.loading);

  const {
    register,
    handleSubmit,
    getValues,
    setFocus,
    formState: { submitCount },
  } = useForm<IUserChangePassword>({
    mode: "onChange",
  });
  const onSubmit = handleSubmit((data) => {
    if (getValues().newpassword !== getValues().newpassword2) {
      setFocus("newpassword2");
    } else {
      dispatch(Loading(true));
      changePassword(data.oldpassword, data.newpassword, token)
        .then(() => {
          setResponseMessage("Mot de passe modifié !");
          setResponseState("success");
          dispatch(Loading(false));
        })
        .catch((err: AxiosError) => {
          setResponseMessage(err.response?.data);
          setResponseState("alert");
          dispatch(Loading(false));
        });
    }
  });

  return (
    <GenericsStyled.Container>
      <AccountContainer>
        <ChangePasswordContainer background="none" border="none">
          <FormStyled.Form onSubmit={onSubmit}>
            <GenericsStyled.Alert className={responseState}>
              {responseMessage}
            </GenericsStyled.Alert>
            <React.Fragment>
              <FormStyled.Label htmlFor="oldpassword">
                Ancien mot de passe
              </FormStyled.Label>
              <AccountInput
                {...register("oldpassword", { required: true })}
                id="oldpassword"
                type="password"
                placeholder="Ancien mot de passe"
              />
            </React.Fragment>
            <React.Fragment>
              <FormStyled.Label htmlFor="oldpassword">
                Nouveau mot de passe
              </FormStyled.Label>
              <AccountInput
                {...register("newpassword", { required: true })}
                id="newpassword"
                type="password"
                placeholder="Nouveau mot de passe"
              />
            </React.Fragment>
            <React.Fragment>
              <FormStyled.Label htmlFor="oldpassword">
                Vérifier nouveau mot de passe
              </FormStyled.Label>
              <AccountInput
                {...register("newpassword2", { required: true })}
                id="newpassword2"
                type="password"
                placeholder="Vérifier nouveau mot de passe"
              />
            </React.Fragment>
            <FormStyled.Error
              hidden={
                !(
                  submitCount > 0 &&
                  getValues().newpassword !== getValues().newpassword2 &&
                  getValues().oldpassword !== ""
                )
              }
            >
              Les nouveaux mot de passe ne sont pas identiques.
            </FormStyled.Error>
            <FormStyled.FormButtonsContainer>
              <FormStyled.SubmitButton type="submit" disabled={loading}>
                Soumettre
                <GenericsStyled.SpinnerIcon icon={faSpinner} />
              </FormStyled.SubmitButton>
            </FormStyled.FormButtonsContainer>
          </FormStyled.Form>
        </ChangePasswordContainer>
      </AccountContainer>
    </GenericsStyled.Container>
  );
};
