import { Sorting } from "../models/api";
import { TableRow } from "../models/tables";

export const sortingAction = (sorting: Sorting, column: string) => {
  let newDirection: "asc" | "desc";

  if (sorting.column === column) {
    if (sorting.direction === "asc") {
      newDirection = "desc";
    } else {
      newDirection = "asc";
    }
  } else {
    newDirection = "desc";
  }
  return {
    column: column,
    direction: newDirection,
  };
};

export function formatDateRows(rows: TableRow[]): TableRow[] {
  return rows.map((item) => {
    Object.keys(item).forEach((key) => {
      if (key === "echeancedate") {
        const date = new Date(item[key]);
        item[key] = `${("0" + date.getDate()).slice(-2)}-${(
          "0" +
          (date.getMonth() + 1)
        ).slice(-2)}-${date.getFullYear()}`;
      }
    });
    return item;
  });
}
