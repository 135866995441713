import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { GenericsStyled } from "../components/styled-components/generics";
import { TableCustom } from "../components/table/table";
import {
  ApiTableResponse,
  SentFilters,
  Sorting,
  UsedFilter,
} from "../models/api";
import { TableRow } from "../models/tables";
import { Api } from "../services/tables";
import { useAppSelector } from "../store";
import { sortingAction, formatDateRows } from "../utilities/sorting";
import { Filters } from "../components/filters";

const RowStyled = styled(GenericsStyled.Row)`
  overflow-x: auto;
`;

const ContainerForTable = styled(GenericsStyled.Container)`
  width: inherit;
  padding: 0 30px;
`;

export const Nakedcall = () => {
  const initialSorting: Sorting = {
    column: "otm",
    direction: "desc",
  };

  const filters: UsedFilter[] = [
    {
      libelle: "Libéllé",
      name: "libelle",
      type: "single",
    },
    {
      libelle: "Code",
      name: "code",
      type: "single",
    },
    {
      libelle: "Cours",
      name: "last",
      type: "double",
    },
    {
      libelle: "Jours avant expiration",
      name: "jouravantecheance",
      type: "double",
    },
    {
      libelle: "Strike",
      name: "calls_strike",
      type: "double",
    },
    {
      libelle: "Calls_bid",
      name: "calls_bid",
      type: "double",
    },
    {
      libelle: "PXMOY",
      name: "pxmoy",
      type: "double",
    },
    {
      libelle: "RAB",
      name: "rab",
      type: "double",
    },
    {
      libelle: "RAM",
      name: "ram",
      type: "double",
    },
    {
      libelle: "OTM%",
      name: "otm",
      type: "double",
    },
  ];

  const [fullTable, setFullTable] = useState<TableRow[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useState<SentFilters>({});
  const [rowsMax, setRowsMax] = useState(15);
  const token = useAppSelector((state) => state.user.token);
  const [sorting, setSorting] = useState<Sorting>(initialSorting);

  const ApiCall = useCallback(
    (offset: number = 0, filters: SentFilters = currentFilters) => {
      setIsLoading(true);
      Api.nakedCallList(token, offset, filters, sorting)
        .then((response: AxiosResponse<ApiTableResponse>) => {
          const rows = formatDateRows(response.data.Rows);
          setFullTable(rows);
          if (response.data.Pagination.Offset === 0) {
            setCurrentPage(1);
          } else {
            setCurrentPage(response.data.Pagination.Offset / rowsMax + 1);
          }
          setPageCount(Math.ceil(response.data.Pagination.Total / rowsMax));
          setTotalCount(response.data.Pagination.Total);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [currentFilters, rowsMax, token, sorting]
  );

  useEffect(() => {
    ApiCall();
  }, [ApiCall]);

  const changePage = (page: number) => {
    ApiCall((page - 1) * rowsMax, currentFilters);
    setCurrentPage(page);
  };

  const refreshFilters = (data: SentFilters) => {
    console.log(data);
    setCurrentFilters(data);
  };

  return (
    <ContainerForTable marginTop="90px">
      <RowStyled>
        <TableCustom
          rows={fullTable}
          isLoading={isLoading}
          currentPage={currentPage}
          linkBoursorama={"codeaction"}
          pageCount={pageCount}
          totalCount={totalCount}
          changePage={changePage}
          specificLastLink={false}
          sortingAction={(column: string) =>
            setSorting(sortingAction(sorting, column))
          }
          currentSorting={sorting}
        />
        <Filters onChange={refreshFilters} filters={filters}></Filters>
      </RowStyled>
    </ContainerForTable>
  );
};
