import { FunctionComponent } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { Login } from "../pages/login";
import { Password } from "../pages/password";
import { Homepage } from "../pages/homepage";
import { useAppSelector } from "../store";
import { Chain } from "../pages/chain";
import { Nakedcall } from "../pages/nakedcall";
import { Nakedput } from "../pages/nakedput";
import { Account } from "../pages/account";
import { ChangePassword } from "../pages/change-password";

export const GlobalRouter: FunctionComponent = ({ children }) => {
  return (
    <Router>
      {children}
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/password">
          <Password />
        </Route>
        <PrivateRoute exact path="/nakedput">
          <Nakedput />
        </PrivateRoute>
        <PrivateRoute exact path="/nakedcall">
          <Nakedcall />
        </PrivateRoute>
        <PrivateRoute exact path="/account">
          <Account />
        </PrivateRoute>
        <PrivateRoute exact path="/change-password">
          <ChangePassword />
        </PrivateRoute>
        <PrivateRoute exact path="/">
          <Homepage />
        </PrivateRoute>
        <PrivateRoute exact path="/:chain">
          <Chain />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

const PrivateRoute: FunctionComponent<{ path: string; exact: boolean }> = ({
  children,
  path,
  exact,
}) => {
  const token = useAppSelector((state) => state.user.token);
  if (token) {
    return (
      <Route path={path} exact={exact}>
        {children}
      </Route>
    );
  } else {
    return <Redirect to="/login" />;
  }
};
