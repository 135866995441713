import {
  faLongArrowAltRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { ConnectionTry } from "../actions/user";
import { IUserConnectionTry } from "../models/user";
import { useAppDispatch, useAppSelector } from "../store";
import { FormStyled } from "../components/styled-components/form";
import { GenericsStyled } from "../components/styled-components/generics";
import { LinkStyled } from "../components/styled-components/link";
import { Redirect } from "react-router";
import background from "../assets/background.jpg";
import styled from "styled-components";
import { Loading } from "../actions/loading";

const LoginContainer = styled(FormStyled.Container)`
  background-image: url("${background}");
  background-size: cover;
  position: absolute;
`;

export const Login = () => {
  const dispatch = useAppDispatch();
  dispatch(Loading(false));

  const { register, handleSubmit } = useForm<IUserConnectionTry>();

  const onSubmit = handleSubmit((data) => {
    dispatch(ConnectionTry(data));
  });

  const token = useAppSelector((state) => state.user.token);
  const loading = useAppSelector((state) => state.loading);

  if (token) return <Redirect to="/"></Redirect>;

  return (
    <LoginContainer>
      <FormStyled.FormContainer marginTop="100px">
        <FormStyled.Title>Connexion</FormStyled.Title>
        <FormStyled.Form onSubmit={onSubmit}>
          <FormStyled.Label htmlFor="username">Adresse e-mail</FormStyled.Label>
          <FormStyled.Input
            {...register("username", { required: true })}
            id="username"
            type="email"
            placeholder="Adresse e-mail"
          />
          <FormStyled.Label htmlFor="password">Mot de passe</FormStyled.Label>
          <FormStyled.Input
            {...register("password", { required: true })}
            id="password"
            type="password"
            placeholder="Mot de passe"
          />
          <FormStyled.FormButtonsContainer>
            <FormStyled.SubmitButton type="submit" disabled={loading}>
              Connexion
              <GenericsStyled.SpinnerIcon icon={faSpinner} />
            </FormStyled.SubmitButton>
          </FormStyled.FormButtonsContainer>
        </FormStyled.Form>
        <FormStyled.FormLinksContainer>
          <LinkStyled.Link to="/password">
            Mot de passe oublié
            <LinkStyled.LinkIconRight icon={faLongArrowAltRight} />
          </LinkStyled.Link>
        </FormStyled.FormLinksContainer>
      </FormStyled.FormContainer>
    </LoginContainer>
  );
};
