import {
  CONNECTION_FAILED,
  CONNECTION_SUCCEED,
  DISCONNECTION,
  IUserCombinatedState,
} from "../actions/user";
import { IUserState } from "../models/user";

const initialState: IUserState = {
  token: "",
  refresh: "",
  error: {
    status: "",
  },
};

const user = (
  state: IUserState = initialState,
  action: IUserCombinatedState
): IUserState => {
  switch (action.type) {
    case CONNECTION_SUCCEED:
      return {
        ...initialState,
        token: action.payload.token,
        refresh: action.payload.refresh,
      };
    case CONNECTION_FAILED:
      return {
        ...initialState,
        error: {
          status: action.payload.status,
        },
      };
    case DISCONNECTION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default user;
