import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const LinkStyled = {
  Link: styled(Link)`
    font-size: 12px;
    color: ${({ theme }) => theme.colorAccentuated};
    text-decoration: inherit;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    margin-right: 15px;
  `,
  LinkIconRight: styled(FontAwesomeIcon)`
    margin-left: 10px;
  `,
  LinkIconLeft: styled(FontAwesomeIcon)`
    margin-right: 10px;
  `,
};
