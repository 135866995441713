import axios, { AxiosResponse } from "axios";
import { Sorting, SentFilters } from "../models/api";
import { server } from "../env";
const serveurIP = server + "/data/";

export const Api = {
  optionList: (
    token: string,
    offset: number,
    filters: SentFilters,
    sorting: Sorting
  ): Promise<AxiosResponse> => {
    return axios({
      method: "post",
      url: serveurIP + "list",
      data: new URLSearchParams({
        token: token,
        offset: offset.toString(),
        ...filters,
        sort: sorting.column,
        sort_direction: sorting.direction,
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  },
  chainList: (
    token: string,
    offset: number,
    filters: SentFilters,
    chain: string,
    sorting: Sorting
  ): Promise<AxiosResponse> => {
    return axios({
      method: "post",
      url: serveurIP + "chain",
      data: new URLSearchParams({
        token: token,
        offset: offset.toString(),
        ...filters,
        code: chain,
        sort: sorting.column,
        sort_direction: sorting.direction,
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  },
  nakedPutList: (
    token: string,
    offset: number,
    filters: SentFilters,
    sorting: Sorting
  ): Promise<AxiosResponse> => {
    return axios({
      method: "post",
      url: serveurIP + "nakedput",
      data: new URLSearchParams({
        token: token,
        offset: offset.toString(),
        ...filters,
        sort: sorting.column,
        sort_direction: sorting.direction,
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  },
  nakedCallList: (
    token: string,
    offset: number,
    filters: SentFilters,
    sorting: Sorting
  ): Promise<AxiosResponse> => {
    return axios({
      method: "post",
      url: serveurIP + "nakedcall",
      data: new URLSearchParams({
        token: token,
        offset: offset.toString(),
        ...filters,
        sort: sorting.column,
        sort_direction: sorting.direction,
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  },
};
