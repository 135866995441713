import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";

import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import rootSaga from "./rootSaga";
import theme from "./reducers/theme";
import user from "./reducers/user";
import loading from "./reducers/loading";

const sagaMiddleware = createSagaMiddleware();

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  theme,
  user,
  loading,
});

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootState, any>(
  persistConfig,
  rootReducer
);

export const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware)
);

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

sagaMiddleware.run(rootSaga);
