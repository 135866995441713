import {
  ConnectionFailed,
  ConnectionSucceed,
  CONNECTION_TRY,
  ICONNECTION_TRY,
} from "../actions/user";
import { call, put, takeLatest } from "redux-saga/effects";
import connectionStandard from "../services/user";
import { AxiosResponse } from "axios";
import { Loading } from "../actions/loading";

function* connection(action: ICONNECTION_TRY) {
  yield put(Loading(true));
  try {
    const tokens: AxiosResponse = yield call(
      connectionStandard,
      action.payload.username,
      action.payload.password
    );
    yield put(ConnectionSucceed({ ...tokens.data }));
  } catch (e) {
    yield put(ConnectionFailed(e.response.status));
    yield put(Loading(false));
  }
  yield put(Loading(false));
}

function* userSaga() {
  yield takeLatest(CONNECTION_TRY, connection);
}

export default userSaga;
