import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { IUserRetrievePassword } from "../models/user";
import { FormStyled } from "../components/styled-components/form";
import { LinkStyled } from "../components/styled-components/link";
import { useAppSelector } from "../store";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

const PasswordContainer = styled(FormStyled.FormContainer)`
  height: 265px;
`;

export const Password = () => {
  const { register, handleSubmit } = useForm<IUserRetrievePassword>();

  const onSubmit = handleSubmit((data) => {
    console.log(data);
  });

  const token = useAppSelector((state) => state.user.token);

  if (token) return <Redirect to="/"></Redirect>;

  return (
    <FormStyled.Container>
      <PasswordContainer>
        <FormStyled.Title>Mot de passe oublié ?</FormStyled.Title>
        <FormStyled.Form onSubmit={onSubmit}>
          <FormStyled.Label htmlFor="username">Nom de compte</FormStyled.Label>
          <FormStyled.Input
            {...register("username", { required: true })}
            name="username"
            id="username"
            type="text"
            placeholder="Nom de compte"
          />
          <FormStyled.FormButtonsContainer>
            <FormStyled.SubmitButton type="submit">
              Soumettre
            </FormStyled.SubmitButton>
          </FormStyled.FormButtonsContainer>
        </FormStyled.Form>
        <FormStyled.FormLinksContainer>
          <LinkStyled.Link to="/">
            <LinkStyled.LinkIconLeft icon={faLongArrowAltLeft} />
            Retour
          </LinkStyled.Link>
        </FormStyled.FormLinksContainer>
      </PasswordContainer>
    </FormStyled.Container>
  );
};
