import axios, { AxiosResponse } from "axios";
import { SentFilters } from "../models/api";
import { server } from "../env";
const serveurIP = server + "/data/";

export const Api = {
  createFilter: (
    token: string,
    filters: SentFilters
  ): Promise<AxiosResponse> => {
    return axios({
      method: "post",
      url: serveurIP + "setFilters",
      data: new URLSearchParams({
        token: token,
        ...filters,
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  },
  deleteFilters: (token: string, filter: string): Promise<AxiosResponse> => {
    return axios({
      method: "post",
      url: serveurIP + "deleteFilters",
      data: new URLSearchParams({
        token: token,
        filter: filter,
      }),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
  },
};
