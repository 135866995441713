export const LOADING = "LOADING";

/**
 * @description User Action - Connection Failed
 */
export interface ILOADING {
  type: typeof LOADING;
  payload: boolean;
}

/**
 * @description Loading state
 * @param None
 * @returns {ILOADING} type `DISCONNECTION`
 */
export const Loading = (loading: boolean): ILOADING => ({
  type: LOADING,
  payload: loading,
});
