import { SWITCH_THEME, ThemeActionTypes } from "../actions/theme";
import { IThemeTypes } from "../models/theme";

const theme = (
  state: IThemeTypes = IThemeTypes.DARK,
  action: ThemeActionTypes
) => {
  switch (action.type) {
    case SWITCH_THEME:
      return action.payload;
    default:
      return state;
  }
};

export default theme;
