export enum IThemeTypes {
  DARK = "DARK",
  CLASSIC = "CLASSIC",
}

export interface IGeneralCSS {
  padding: string;
  borderRadius: string;
  mobileBreakpoint: string;
}

export interface IThemeParams extends IGeneralCSS {
  backgroundBody: string;
  backgroundBasic: string;
  color: string;
  colorAccentuated: string;
  borderColor: string;
  inputBackgroundColor: string;
  inputBorderColor: string;
  errorColor: string;
  errorAlertColor: string;
  errorBorderColor: string;
  errorBackgroundColor: string;
  formBorderColor: string;
  tableHeadFixedBGColor: string;
  tableHeadFixedHoverBGColor: string;
}
