import { FiltersStyled } from "./styled-components/filters";
import { useForm } from "react-hook-form";
import { SentFilters, UsedFilter } from "../models/api";
import React, { useCallback, useEffect, useState } from "react";
import { getFilters } from "../services/user";
import { useAppSelector } from "../store";
import { Button, Modal } from "semantic-ui-react";
import { GenericsStyled } from "./styled-components/generics";
import { FormStyled } from "./styled-components/form";
import { Api } from "../services/filters";
import { useTranslation } from "react-i18next";

export const Filters = (props: {
  onChange: Function;
  filters: UsedFilter[];
}) => {
  const [filters, setFilters] = useState([]);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [storedFiltersValues, setStoredFiltersValues] = useState<SentFilters[]>(
    []
  );
  const { t } = useTranslation();
  const { register, getValues, setValue, formState, reset } =
    useForm<SentFilters>();
  const { register: registerFilter, getValues: getValuesFilter } =
    useForm<SentFilters>();
  const token = useAppSelector((state) => state.user.token);
  const [openModalAdd, setOpenModalAdd] = useState(false);

  const getFiltersFromServer = useCallback(() => {
    return new Promise<void>((resolve) => {
      getFilters(token).then((response) => {
        setFilters(
          response.data.map((item: SentFilters, index: number) => ({
            key: item.filter + index,
            text: item.filter,
            value: item.filter,
          }))
        );
        setStoredFiltersValues(response.data);
        resolve();
      });
    });
  }, [token]);

  useEffect(() => {
    getFiltersFromServer();
  }, [getFiltersFromServer]);

  const createFilterValues = () => {
    return {
      ...getValues(),
      ...getValuesFilter(),
    };
  };

  const onSubmitFilters = () => {
    Api.createFilter(token, createFilterValues()).then(() => {
      getFiltersFromServer();
    });
  };

  const onChangeFilter = (e: MouseEvent, eventProps: { value: string }) => {
    const currentFilter = storedFiltersValues.find(
      (item) => item.filter === eventProps.value
    );

    setDropdownValue(eventProps.value);

    if (currentFilter) {
      Object.keys(currentFilter).forEach((item) => {
        setValue(item, currentFilter[item], { shouldDirty: true });
      });
      props.onChange(getValues());
    }
  };

  const resetForm = () => {
    props.filters.forEach((item) => {
      if (item.type === "double") {
        setValue(item.name + "_min", "");
        setValue(item.name + "_max", "");
      } else {
        setValue(item.name, "");
      }
    });
    reset();
    props.onChange(getValues());
  };

  const refreshFilters = () => {
    props.onChange(getValues());
  };

  const removeFilter = () => {
    if (!removeLoading) {
      setRemoveLoading(true);
      Api.deleteFilters(token, getValues().filter)
        .then((response) => {
          setDropdownValue("");
          resetForm();
          setFilters(
            response.data.map((item: SentFilters, index: number) => ({
              key: item.filter + index,
              text: item.filter,
              value: item.filter,
            }))
          );
          setStoredFiltersValues(response.data);
          setRemoveLoading(false);
        })
        .catch(() => {
          setDropdownValue("");
          resetForm();
          getFiltersFromServer().then(() => {
            setRemoveLoading(false);
          });
        });
    }
  };

  const FilterBlock = (filters: UsedFilter[]) => {
    const filtersRender: JSX.Element[] = [];

    filters.forEach((item) => {
      if (item.type === "single") {
        filtersRender.push(
          <FiltersStyled.SimpleInputContainer
            className="ui input"
            key={item.libelle}
          >
            <FiltersStyled.InputCustom
              placeholder={t(item.libelle)}
              defaultValue=""
              {...register(item.name)}
            />
          </FiltersStyled.SimpleInputContainer>
        );
      } else {
        filtersRender.push(
          <FiltersStyled.DoubleInputContainer
            className="ui input"
            key={item.libelle}
          >
            <FiltersStyled.InputTitle>
              {t(item.libelle.toLowerCase())}
            </FiltersStyled.InputTitle>
            <FiltersStyled.InputCustom
              placeholder="Min"
              defaultValue=""
              {...register(item.name + "_min")}
            />
            <FiltersStyled.InputCustom
              placeholder="Max"
              defaultValue=""
              {...register(item.name + "_max")}
            />
          </FiltersStyled.DoubleInputContainer>
        );
      }
    });

    return filtersRender;
  };

  return (
    <>
      <FiltersStyled.FiltersContainer>
        <FiltersStyled.SwitchFilterContainer>
          <FiltersStyled.DropdownCustom
            placeholder="Filters"
            fluid
            selection
            options={filters}
            onChange={onChangeFilter}
            value={dropdownValue}
          />
          <FiltersStyled.AddButton onClick={() => setOpenModalAdd(true)} icon>
            <FiltersStyled.AddIcon name="check" />
          </FiltersStyled.AddButton>
          <FiltersStyled.RemoveButton icon onClick={removeFilter}>
            <FiltersStyled.RemoveIcon name="close" />
          </FiltersStyled.RemoveButton>
        </FiltersStyled.SwitchFilterContainer>
      </FiltersStyled.FiltersContainer>
      <Modal
        size={"mini"}
        open={openModalAdd}
        onClose={() => setOpenModalAdd(false)}
      >
        <GenericsStyled.ModalHeader>
          Ajouter un filtre
        </GenericsStyled.ModalHeader>
        <GenericsStyled.ModalContent>
          <p>Veuillez entrer le nom de votre filtre</p>
          <FormStyled.Input
            {...registerFilter("filter")}
            id="filter"
            type="filter"
            placeholder="Filtre"
          />
        </GenericsStyled.ModalContent>
        <GenericsStyled.ModalAction>
          <Button negative onClick={() => setOpenModalAdd(false)}>
            Non
          </Button>
          <Button
            positive
            onClick={() => {
              onSubmitFilters();
              setOpenModalAdd(false);
            }}
          >
            Oui
          </Button>
        </GenericsStyled.ModalAction>
      </Modal>
      <FiltersStyled.FiltersContainer>
        <FiltersStyled.FiltersTitle>Filtres</FiltersStyled.FiltersTitle>
        {FilterBlock(props.filters)}
        <FiltersStyled.ResetFormButtonContainer hidden={!formState.isDirty}>
          <FiltersStyled.ResetFormButton onClick={refreshFilters} color="green">
            Rechercher
          </FiltersStyled.ResetFormButton>
          <FiltersStyled.ResetFormButton onClick={resetForm} color="red">
            Reset
          </FiltersStyled.ResetFormButton>
        </FiltersStyled.ResetFormButtonContainer>
      </FiltersStyled.FiltersContainer>
    </>
  );
};
