import { Button, Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";

export const FiltersStyled = {
  DoubleInputContainer: styled.span`
    width: calc(25% - 22.5px);
    margin-bottom: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 30px;
    input {
      width: calc(50% - 7.5px);
      flex: inherit !important;
    }
    @media screen and (max-width: 1170px) {
      width: 100%;
      margin-right: 0;
    } ;
  `,
  SimpleInputContainer: styled.span`
    width: calc(25% - 22.5px);
    margin-right: 30px;
    margin-bottom: 15px;
    height: 37.33px;
    display: flex;
    align-self: flex-end;
    align-items: center;
    @media screen and (max-width: 1170px) {
      width: 100%;
      margin-right: 0;
    } ;
  `,
  SwitchFilterContainer: styled.span`
    width: calc(50% - 11.25px);
    margin-right: 30px;
    margin-bottom: 15px;
    height: 37.33px;
    display: flex;
    align-self: flex-end;
    align-items: center;
    @media screen and (max-width: 1170px) {
      width: 100%;
      margin-right: 0;
    } ;
  `,
  FiltersContainer: styled.div`
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    span:nth-of-type(4n) {
      margin-right: 0;
    }
  `,
  ResetFormButtonContainer: styled.div`
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  `,
  RefreshButtonContainer: styled.div`
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: right;
  `,
  ResetFormButton: styled(Button)`
    margin-right: 10px !important;
    &[hidden] {
      display: none !important;
    }
  `,
  FiltersTitle: styled.div`
    text-transform: uppercase;
    font-size: 16px;
    color: white;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  `,
  InputTitle: styled.span`
    font-size: 14px;
    text-transform: uppercase;
    color: white;
    width: 100%;
    margin-bottom: 5px;
    text-align: center;
  `,
  InputCustom: styled.input`
    background: #252424 !important;
    color: white !important;
  `,
  DropdownCustom: styled(Dropdown)`
    background: #252424 !important;
    color: white !important;
    input {
      color: white;
    }
  `,
  AddButton: styled(Button)`
    background-color: #0f5132 !important;
    border: 1px solid #0f5132 !important;
    margin-left: 15px !important;
  `,
  AddIcon: styled(Icon)`
    color: white;
  `,
  RemoveButton: styled(Button)`
    background-color: #a5222e !important;
    border: 1px solid #a5222e !important;
    margin-left: 15px !important;
  `,
  RefreshButton: styled(Button)`
    background-color: #252424 !important;
    border: 1px solid #252424 !important;
  `,
  RemoveIcon: styled(Icon)`
    color: white;
  `,
};
