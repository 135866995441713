import axios, { AxiosResponse } from "axios";
import md5 from "md5";
import { server } from "../env";
const serveurIP = server;

export const connectionStandard = async (
  username: string,
  password: string
): Promise<AxiosResponse> => {
  return axios({
    method: "post",
    url: serveurIP + "/user/connection",
    data: new URLSearchParams({
      email: username,
      password: md5(password),
    }),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const changePassword = async (
  oldpassword: string,
  newpassword: string,
  token: string
): Promise<AxiosResponse> => {
  return axios({
    method: "post",
    url: serveurIP + "/privateUser/change-password",
    data: new URLSearchParams({
      oldpassword: md5(oldpassword),
      newpassword: md5(newpassword),
      token: token,
    }),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getInfos = async (token: string): Promise<AxiosResponse> => {
  return axios({
    method: "post",
    url: serveurIP + "/data/infos",
    data: new URLSearchParams({
      token: token,
    }),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getFilters = async (token: string): Promise<AxiosResponse> => {
  return axios({
    method: "post",
    url: serveurIP + "/data/getFilters",
    data: new URLSearchParams({
      token: token,
    }),
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export default connectionStandard;
