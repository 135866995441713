import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../store";
import { GenericsStyled } from "../styled-components/generics";
import { Disconnection } from "../../actions/user";
import { Link, useLocation } from "react-router-dom";

const Nav = styled.nav`
  height: 75px;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  color: ${(props) => props.theme.color};
  background-color: ${(props) => props.theme.backgroundBasic};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
`;

const Logo = styled.span`
  display: flex;
  align-items: center;
  font-family: "Lobster", cursive;
  font-size: 35px;
`;

const NavbarActions = styled.span`
  display: flex;
`;

const Menu = styled.ul`
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
`;

const MenuItem = styled.li`
  font-size: 14px;
  text-transform: uppercase;
  margin-right: 30px;
  cursor: pointer;
  &.active {
    border-bottom: 2px solid;
  }
  a {
    color: ${(props) => props.theme.color};
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

export const NavBar = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.user.token);

  return (
    <Nav>
      <GenericsStyled.Container>
        <GenericsStyled.Row justifyContent="space-between">
          <Logo>EurOptions</Logo>
          <NavbarActions>
            {token ? (
              <Menu>
                <MenuItem
                  className={
                    location.pathname.substr(1) === "" ? "active" : undefined
                  }
                >
                  <Link to="/">Liste</Link>
                </MenuItem>
                <MenuItem
                  className={
                    location.pathname.substr(1) === "nakedput"
                      ? "active"
                      : undefined
                  }
                >
                  <Link to="/nakedput">Naked Put</Link>
                </MenuItem>
                <MenuItem
                  className={
                    location.pathname.substr(1) === "nakedcall"
                      ? "active"
                      : undefined
                  }
                >
                  <Link to="/nakedcall">Naked Call</Link>
                </MenuItem>
                <MenuItem
                  className={
                    location.pathname.substr(1) === "account"
                      ? "active"
                      : undefined
                  }
                >
                  <Link to="/account">Compte</Link>
                </MenuItem>
                <MenuItem onClick={() => dispatch(Disconnection())}>
                  Déconnexion
                </MenuItem>
              </Menu>
            ) : null}
          </NavbarActions>
        </GenericsStyled.Row>
      </GenericsStyled.Container>
    </Nav>
  );
};
