import { ILOADING, LOADING } from "../actions/loading";

const loading = (state: boolean = false, action: ILOADING) => {
  switch (action.type) {
    case LOADING:
      return action.payload;
    default:
      return state;
  }
};

export default loading;
