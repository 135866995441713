import { IGeneralCSS, IThemeParams } from "../models/theme";

const GeneralCSS: IGeneralCSS = {
  padding: "15px",
  borderRadius: "5px",
  mobileBreakpoint: "1170px",
};

export const ClassicTheme: IThemeParams = {
  ...GeneralCSS,
  backgroundBody: "white",
  backgroundBasic: "white",
  color: "black",
  colorAccentuated: "#457dbb",
  borderColor: "blue",
  inputBackgroundColor: "white",
  inputBorderColor: "#e2e2e2",
  errorColor: "#c84747",
  errorAlertColor: "#832029",
  errorBorderColor: "#f5c2c7",
  errorBackgroundColor: "#f8d7da",
  formBorderColor: "#e2e2e2",
  tableHeadFixedBGColor: "#252424",
  tableHeadFixedHoverBGColor: "#1f1e1e",
};

export const DarkTheme: IThemeParams = {
  ...GeneralCSS,
  backgroundBody: "#121212",
  backgroundBasic: "#1F1B24",
  color: "white",
  colorAccentuated: "#f9a825",
  borderColor: "blue",
  inputBackgroundColor: "#2a2332",
  inputBorderColor: "#2a2332",
  errorColor: "#c84747",
  errorAlertColor: "white",
  errorBorderColor: "#a54d55",
  errorBackgroundColor: "#922f38",
  formBorderColor: "#2a2332",
  tableHeadFixedBGColor: "#252424",
  tableHeadFixedHoverBGColor: "#1f1e1e",
};
