import styled, { DefaultTheme } from "styled-components";
import { Link } from "react-router-dom";

interface FormContainerType {
  background?: string;
  border?: string;
  marginTop?: string;
}

interface FormLinksContainerType {
  padding?: string;
  theme: DefaultTheme;
}

export const FormStyled = {
  Container: styled.div`
    padding-top: 50px;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
  `,
  FormContainer: styled.div`
    width: 400px;
    height: 352px;
    background: ${(props: FormContainerType) =>
      props.background ? "none !important" : "inherit"};
    background-color: ${({ theme }) => theme.backgroundBasic};
    border-radius: ${({ theme }) => theme.borderRadius};
    margin-top: ${(props: FormContainerType) =>
      props.border ? "0" : props.marginTop};
    border: ${(props: FormContainerType) =>
      props.border ? "none" : "1px solid"};
    border-color: ${({ theme }) => theme.formBorderColor};
    @media screen and (max-width: 460px) {
      width: calc(100% - 60px);
    }
    background-image: none;
  `,
  Title: styled.div`
    font-size: 18px;
    border-bottom: 1px solid ${({ theme }) => theme.formBorderColor};
    padding: ${({ theme }) => theme.padding};
    background-color: ${({ theme }) => theme.backgroundBasic};
  `,
  Form: styled.form`
    border-bottom: 1px solid ${({ theme }) => theme.formBorderColor};
    padding: ${({ theme }) => theme.padding};
    background-color: ${({ theme }) => theme.backgroundBasic};
  `,
  Label: styled.label`
    display: block;
    margin-bottom: 10px;
  `,
  Input: styled.input`
    width: 100%;
    padding: ${({ theme }) => theme.padding};
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ theme }) => theme.inputBackgroundColor};
    border: none;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.color};
    border: 1px solid ${({ theme }) => theme.inputBorderColor};
    &:disabled {
      color: grey;
      cursor: not-allowed;
    }
  `,
  Error: styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.errorColor};
    display: block;
    margin-top: -25px;
    margin-bottom: 20px;
    padding: 2.5px 0px;
    &[hidden] {
      display: none;
    }
  `,
  SubmitButton: styled.button`
    border: none;
    padding: 10px 15px;
    background-color: #457dbb;
    border-radius: ${({ theme }) => theme.borderRadius};
    color: white;
    cursor: pointer;
    svg {
      display: none;
    }
    &:hover {
      background-color: #336193;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: #7898bb;
      svg {
        display: inline-block;
      }
    }
  `,
  InscriptionButton: styled(Link)`
    border: none;
    padding: 10px 15px;
    background-color: #1a7d34;
    font-family: sans-serif;
    border-radius: ${({ theme }) => theme.borderRadius};
    color: white;
    cursor: pointer;
    margin-left: 10px;
    &:hover {
      color: white;
      background-color: #1b692f;
    }
  `,
  FormButtonsContainer: styled.span`
    margin-top: 5px;
    display: flex;
    background-color: ${({ theme }) => theme.backgroundBasic};
  `,
  FormLinksContainer: styled.span`
    padding: ${(props: FormLinksContainerType) =>
      props.padding ? props.padding : props.theme.padding};
    margin-top: 5px;
    display: flex;
    background-color: ${({ theme }) => theme.backgroundBasic};
  `,
};
