import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { GenericsStyled } from "../components/styled-components/generics";
import { TableCustom } from "../components/table/table";
import { SentFilters, Sorting, UsedFilter } from "../models/api";
import { TableRow } from "../models/tables";
import { Api } from "../services/tables";
import { useAppSelector } from "../store";
import { formatDateRows, sortingAction } from "../utilities/sorting";
import { Filters } from "../components/filters";

const RowStyled = styled(GenericsStyled.Row)`
  overflow-x: auto;
`;

const ContainerForTable = styled(GenericsStyled.Container)`
  width: inherit;
  padding: 0 30px;
`;

const filters: UsedFilter[] = [
  {
    libelle: "Jours avant expiration",
    name: "jouravantecheance",
    type: "double",
  },
  {
    libelle: "Strike",
    name: "calls_strike",
    type: "double",
  },
  {
    libelle: "Calls bid",
    name: "calls_bid",
    type: "double",
  },
  {
    libelle: "Calls ask",
    name: "calls_ask",
    type: "double",
  },
  {
    libelle: "Puts bid",
    name: "puts_bid",
    type: "double",
  },
  {
    libelle: "Puts ask",
    name: "puts_ask",
    type: "double",
  },
];

export const Chain = () => {
  const initialSorting: Sorting = {
    column: "jouravantecheance",
    direction: "asc",
  };

  let { chain } = useParams<{ chain: string }>();
  const [fullTable, setFullTable] = useState<TableRow[]>([]);
  const [currentFilters, setCurrentFilters] = useState<SentFilters>({});
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsMax, setRowsMax] = useState(15);
  const token = useAppSelector((state) => state.user.token);
  const [sorting, setSorting] = useState<Sorting>(initialSorting);

  const ApiCall = useCallback(
    (offset: number = 0, filters: SentFilters = currentFilters) => {
      Api.chainList(token, offset, filters, chain, sorting)
        .then((response) => {
          const rows = formatDateRows(response.data.Rows);
          setFullTable(rows);
          if (response.data.Pagination.Offset === 0) {
            setCurrentPage(1);
          } else {
            setCurrentPage(response.data.Pagination.Offset / rowsMax + 1);
          }
          setPageCount(Math.ceil(response.data.Pagination.Total / rowsMax));
          setTotalCount(response.data.Pagination.Total);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [currentFilters, rowsMax, token, sorting]
  );

  useEffect(() => {
    ApiCall();
  }, [ApiCall]);

  const changePage = (page: number) => {
    ApiCall((page - 1) * rowsMax, currentFilters);
    setCurrentPage(page);
  };

  const refreshFilters = (data: SentFilters) => {
    setCurrentFilters(data);
  };

  return (
    <ContainerForTable marginTop="125px">
      <RowStyled>
        <TableCustom
          rows={fullTable}
          isLoading={false}
          currentPage={currentPage}
          pageCount={pageCount}
          totalCount={totalCount}
          linkBoursorama={"codeaction"}
          changePage={changePage}
          specificLastLink={false}
          sortingAction={(column: string) =>
            setSorting(sortingAction(sorting, column))
          }
          currentSorting={sorting}
        />
        <Filters onChange={refreshFilters} filters={filters}></Filters>
      </RowStyled>
    </ContainerForTable>
  );
};
