import {
  IUserConnectionFailed,
  IUserConnectionSucceed,
  IUserConnectionTry,
} from "../models/user";

export const CONNECTION_TRY = "CONNECTION_TRY";
export const CONNECTION_SUCCEED = "CONNECTION_SUCCEED";
export const CONNECTION_FAILED = "CONNECTION_FAILED";
export const DISCONNECTION = "DISCONNECTION";

/**
 * @description User Action - Connection Succeed
 */
export interface ICONNECTION_SUCCEED {
  type: typeof CONNECTION_SUCCEED;
  payload: IUserConnectionSucceed;
}

/**
 * @description User Action - Connection Try
 */
export interface ICONNECTION_TRY {
  type: typeof CONNECTION_TRY;
  payload: IUserConnectionTry;
}

/**
 * @description User Action - Connection Failed
 */
export interface ICONNECTION_FAILED {
  type: typeof CONNECTION_FAILED;
  payload: IUserConnectionFailed;
}

/**
 * @description User Action - Disconnection
 */
export interface IDISCONNECTION {
  type: typeof DISCONNECTION;
}

/**
 * @description User Action - Disconnection
 */
export interface IDISCONNECTION {
  type: typeof DISCONNECTION;
}

/**
 * @description All the User Action types
 */
export type IUserCombinatedState =
  | ICONNECTION_SUCCEED
  | ICONNECTION_TRY
  | ICONNECTION_FAILED
  | IDISCONNECTION;

/**
 * @description When user is trying to connect
 * @param IUserConnectionTry object User username & password
 * @returns {ICONNECTION_TRY} type `CONNECTION_TRY` and payload
 */
export const ConnectionTry = (
  userInfos: IUserConnectionTry
): ICONNECTION_TRY => ({
  type: CONNECTION_TRY,
  payload: userInfos,
});

/**
 * @description When user has successfully connect
 * @param IUserConnectionSucceed object User token & tokenRefresh
 * @returns {ICONNECTION_SUCCEED} type `CONNECTION_SUCCEED` and payload
 */
export const ConnectionSucceed = (
  userTokens: IUserConnectionSucceed
): ICONNECTION_SUCCEED => ({
  type: CONNECTION_SUCCEED,
  payload: userTokens,
});

/**
 * @description When user failed to connect
 * @param IUserConnectionFailed
 * @returns {UserAICONNECTION_FAILEDctionTypes} type `CONNECTION_FAILED` and payload
 */
export const ConnectionFailed = (
  error: IUserConnectionFailed
): ICONNECTION_FAILED => ({
  type: CONNECTION_FAILED,
  payload: {
    status: error.status,
  },
});

/**
 * @description User disconnection
 * @param None
 * @returns {IDISCONNECTION} type `DISCONNECTION`
 */
export const Disconnection = (): IDISCONNECTION => ({
  type: DISCONNECTION,
});
