import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { DefaultTheme } from "styled-components";
import { Modal } from "semantic-ui-react";

interface ContainerType {
  theme: DefaultTheme;
  marginTop?: string;
  justifyContent?: string;
}

interface RowType {
  justifyContent?: string;
}

interface SpinnerIconType {
  hidden?: boolean;
}

export const GenericsStyled = {
  SpinnerIcon: styled(FontAwesomeIcon)`
    animation: spinner-animation 1s linear infinite;
    margin-left: 10px;
    display: ${(props: SpinnerIconType) =>
      props.hidden ? "none !important" : ""};
    @keyframes spinner-animation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `,
  Container: styled.div`
    width: 1170px;
    padding: 0 ${(props: { theme: DefaultTheme }) => props.theme.padding};
    margin-left: auto;
    margin-right: auto;
    margin-top: ${(props: ContainerType) => props.marginTop};
    @media (max-width: ${(props: ContainerType) =>
        props.theme.mobileBreakpoint}) {
      width: 100%;
    }
  `,
  Row: styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props: RowType) => props.justifyContent};
  `,
  Alert: styled.div`
    display: none;
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 5px;
    margin-bottom: 30px;
    font-size: 12px;
    &.success {
      display: block;
      color: #0f5132;
      background-color: #d1e7dd;
      border: 1px solid #badbcc;
    }
    &.alert {
      display: block;
      color: ${({ theme }) => theme.errorAlertColor};
      border: 1px solid ${({ theme }) => theme.errorBorderColor};
      border-radius: ${({ theme }) => theme.borderRadius};
      background-color: ${({ theme }) => theme.errorBackgroundColor};
    }
  `,
  ModalHeader: styled(Modal.Header)`
    color: ${({ theme }) => theme.color} !important;
    background-color: ${({ theme }) => theme.backgroundBasic} !important;
    border-bottom: 1px solid ${({ theme }) => theme.formBorderColor} !important;
    border-radius: 0 !important;
  `,
  ModalContent: styled(Modal.Content)`
    color: ${({ theme }) => theme.color} !important;
    background-color: ${({ theme }) => theme.backgroundBasic} !important;
    border-bottom: 1px solid ${({ theme }) => theme.formBorderColor} !important;
  `,
  ModalAction: styled(Modal.Actions)`
    color: ${({ theme }) => theme.color} !important;
    background-color: ${({ theme }) => theme.backgroundBasic} !important;
    border-radius: 0 !important;
  `,
};
