import React, { useCallback } from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Icon,
  Pagination,
  PaginationProps,
  Popup,
  Table,
} from "semantic-ui-react";
import styled from "styled-components";
import { Sorting } from "../../models/api";
import { RowTypes } from "../../models/tables";
import { IThemeTypes } from "../../models/theme";
import { useAppSelector } from "../../store";
import { FiltersStyled } from "../styled-components/filters";

const centered = [
  "code",
  "time",
  "codeaction",
  "echeancedate",
  "jouravantecheance",
  "calls_strike",
  "puts_strike",
  "market",
  "currency",
];

const aligned = [
  "calls_bid",
  "calls_ask",
  "ctos",
  "puts_bid",
  "puts_ask",
  "high",
  "low",
  "last",
  "last_change",
  "otm",
  "pxmoy",
  "rab",
  "ram",
  "vol",
  "volume",
  "oi",
  "asj",
];

const TableContainer = styled.div`
  display: block;
  width: 100%;
  overflow: auto;
  thead {
    display: table-header-group !important;
  }
  .ui.table:not(.unstackable) tbody {
    display: table-row-group !important;
  }
  .ui.table:not(.unstackable) tr {
    display: table-row !important;
    td {
      display: table-cell !important;
    }
  }
  .ui.table:not(.unstackable) tr > th {
    display: table-cell !important;
    td {
      display: table-cell !important;
    }
  }

  .ui.table:not(.unstackable) tr > th,
  .ui.table:not(.unstackable) tr,
  .ui.table:not(.unstackable) tbody {
    padding: 10px !important;
    td {
      padding: 10px !important;
      border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
      border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
    }
  }
`;

const TableStyled = styled(Table)`
  thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 2;
    background-color: ${({ theme }) => theme.tableHeadFixedBGColor} !important;
    &:hover {
      background-color: ${({ theme }) =>
        theme.tableHeadFixedHoverBGColor} !important;
    }
  }
`;

const TableHeaderCellContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
`;

const CustomPagination = styled(Pagination)`
  background: transparent !important;
  display: flex;
  justify-content: flex-end;
  border: none !important;
  box-shadow: none !important;
  a {
    background: #333 !important;
    color: white !important;
    &.active {
      background: #252424 !important;
    }
  }
`;

const ToolArea = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const TotalCount = styled.span``;

const IconInfo = styled(Icon)`
  color: white;
`;

export const TableCustom: FunctionComponent<{
  rows: RowTypes[];
  currentPage: number;
  pageCount: number;
  totalCount: number;
  changePage: (e: number) => void;
  specificLastLink: boolean;
  sortingAction: (column: string) => void;
  currentSorting: Sorting;
  isLoading: boolean;
  linkBoursorama: string;
}> = ({
  rows,
  currentPage,
  pageCount,
  totalCount,
  changePage,
  specificLastLink,
  sortingAction,
  currentSorting,
  isLoading,
  linkBoursorama,
}) => {
  const [columnHeaders, setColumnHeaders] = useState<JSX.Element[]>([]);
  const [rowsValues, setRowsValues] = useState<JSX.Element[]>([]);
  const theme = useAppSelector(({ theme }) => theme);
  const { t } = useTranslation();
  const handlePaginationChange = (e: any, data: PaginationProps) => {
    if (data.activePage && typeof data.activePage == "number") {
      changePage(data.activePage);
    } else {
      changePage(currentPage);
    }
  };

  useEffect(() => {
    init();
  }, [rows]);

  const init = useCallback(() => {
    if (rows && rows[0]) {
      setColumnHeaders(
        Object.keys(rows[0]).map((key, index) => {
          if (linkBoursorama === "codeaction" && key === "codeaction") {
            return (
              <Popup
                key={index}
                content={t("tooltip-url")}
                size="mini"
                mouseEnterDelay={500}
                trigger={
                  <Table.HeaderCell key={key} textAlign="center">
                    <TableHeaderCellContainer>
                      {t("url")}
                    </TableHeaderCellContainer>
                  </Table.HeaderCell>
                }
              />
            );
          }
          return (
            <Popup
              key={index}
              content={t("tooltip-" + key)}
              size="mini"
              mouseEnterDelay={500}
              trigger={
                <Table.HeaderCell
                  key={key}
                  textAlign="center"
                  onClick={() => {
                    sortingAction(key);
                  }}
                >
                  <TableHeaderCellContainer>
                    {t(key)}
                    {key !== "url" && key === currentSorting.column ? (
                      <IconInfo
                        name={
                          "caret " +
                          (currentSorting.direction === "asc" ? "up" : "down")
                        }
                      />
                    ) : null}
                  </TableHeaderCellContainer>
                </Table.HeaderCell>
              }
            />
          );
        })
      );
      setRowsValues(
        rows.map((row, index) => {
          let codeValueRow = Object.entries(row).find(
            (value) => value[0] === "code"
          );
          let codeValue = codeValueRow ? codeValueRow[1] : "";
          return (
            <Table.Row key={index}>
              {Object.entries(row).map((values, index) => {
                let codeActionValue = Object.entries(row).find(
                  (value) => value[0] === "codeaction"
                );
                let codeAction = codeActionValue ? codeActionValue[1] : "";
                if (
                  (values[0] === linkBoursorama &&
                    linkBoursorama === "codeaction") ||
                  values[0] === "codeaction"
                ) {
                  let marketBoursorama = "";

                  switch (row.market) {
                    case "PAR":
                      marketBoursorama = "1rP";
                      break;
                    case "AMS":
                      marketBoursorama = "1rA";
                      break;
                    case "BRU":
                      marketBoursorama = "FF11-";
                      break;
                  }

                  return (
                    <Table.Cell key={index} textAlign="center">
                      <a
                        href={
                          "https://bourse.boursorama.com/cours/" +
                          marketBoursorama +
                          codeAction
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconInfo name="info circle" />
                      </a>
                    </Table.Cell>
                  );
                }
                const valueToDisplay =
                  !isNaN(Number(values[1])) &&
                  values[0] !== "oi" &&
                  values[0] !== "vol" &&
                  values[0] !== "calls_strike" &&
                  values[0] !== "jouravantecheance" &&
                  values[0] !== "volume" &&
                  values[1] !== "0"
                    ? Number(values[1].replace(",", ".")).toFixed(2)
                    : values[1];
                return (
                  <Table.Cell
                    className={
                      centered.includes(values[0])
                        ? "center aligned"
                        : aligned.includes(values[0])
                        ? "right aligned"
                        : undefined
                    }
                    key={index}
                  >
                    {aligned.includes(values[0])
                      ? valueToDisplay
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : valueToDisplay}
                  </Table.Cell>
                );
              })}
              {specificLastLink ? (
                <Table.Cell key={codeValue} textAlign="center">
                  <a href={"/" + codeValue}>
                    <IconInfo name="linkify" />
                  </a>
                </Table.Cell>
              ) : null}
            </Table.Row>
          );
        })
      );
    } else {
      setColumnHeaders([]);
      setRowsValues([]);
    }
  }, [rows, specificLastLink, currentSorting, t, sortingAction]);

  return (
    <React.Fragment>
      <TableContainer>
        <TableStyled celled inverted={theme === IThemeTypes.DARK} sortable>
          <Table.Header>
            <Table.Row>
              {!isLoading ? columnHeaders : null}
              {specificLastLink && rowsValues[0] && !isLoading ? (
                <Table.HeaderCell textAlign="center" key={"link"}>
                  {t("chaine")}
                </Table.HeaderCell>
              ) : null}
            </Table.Row>
          </Table.Header>
          {isLoading ? (
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="center">
                  Chargement en cours...
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ) : rowsValues[0] ? (
            <Table.Body>{rowsValues}</Table.Body>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="center">Pas de résultats</Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </TableStyled>
      </TableContainer>
      <ToolArea>
        <TotalCount>
          Lignes :{" "}
          {15 * currentPage > totalCount ? totalCount : 15 * currentPage} /{" "}
          {totalCount}
        </TotalCount>
        <CustomPagination
          disabled={!(rows && rows[0])}
          activePage={currentPage}
          totalPages={pageCount}
          onPageChange={handlePaginationChange}
        />
        <FiltersStyled.RefreshButton icon onClick={handlePaginationChange}>
          <FiltersStyled.RemoveIcon name="refresh" />
        </FiltersStyled.RefreshButton>
      </ToolArea>
    </React.Fragment>
  );
};
