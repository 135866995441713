import { GenericsStyled } from "../components/styled-components/generics";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { FormStyled } from "../components/styled-components/form";
import React, { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { MemberInfos } from "../models/api";
import { useAppSelector } from "../store";
import { getInfos } from "../services/user";
import { LinkStyled } from "../components/styled-components/link";

const AccountContainer = styled(FormStyled.Container)`
  margin-top: 30px;
`;

const AccountInput = styled(FormStyled.Input)`
  margin-bottom: 30px;
`;

const ChangePasswordContainer = styled(FormStyled.FormLinksContainer)`
  padding: 15px 10px;
  background-color: transparent;
`;

export const Account = () => {
  const token = useAppSelector((state) => state.user.token);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mail, setMail] = useState("");
  const [date, setDate] = useState("");

  const ApiCall = useCallback(() => {
    getInfos(token)
      .then((response: AxiosResponse<MemberInfos>) => {
        const data = response.data;
        setFirstName(data.prenom);
        setLastName(data.nom);
        setMail(data.email);
        let date = new Date(data.abonnement_fin);
        setDate(`${date.getDay()}-${date.getMonth()}-${date.getFullYear()}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  useEffect(() => {
    ApiCall();
  }, [ApiCall]);

  const BlockInput = (props: {
    id: string;
    label: string;
    value: string | number | undefined;
  }) => {
    if (props.value !== "") {
      return (
        <React.Fragment>
          <FormStyled.Label htmlFor={props.id}>{props.label}</FormStyled.Label>
          <AccountInput
            id={props.id}
            value={props.value ? props.value : ""}
            disabled
          />
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  return (
    <GenericsStyled.Container marginTop="125px">
      <AccountContainer>
        <FormStyled.FormContainer background="none" border="none">
          <BlockInput label="Prénom" id="firstName" value={firstName} />
          <BlockInput label="Nom" id="lastName" value={lastName} />
          <BlockInput label="E-mail" id="mail" value={mail} />
          <BlockInput
            label="Expiration de l'abonnement"
            id="date"
            value={date}
          />
          <ChangePasswordContainer padding="0">
            <LinkStyled.Link to="/change-password">
              Changer de mot de passe
              <LinkStyled.LinkIconRight icon={faLongArrowAltRight} />
            </LinkStyled.Link>
          </ChangePasswordContainer>
        </FormStyled.FormContainer>
      </AccountContainer>
    </GenericsStyled.Container>
  );
};
